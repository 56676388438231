<footer class="footer">
    <div class="footer__center center">
        <div class="footer__service">
            <ng-container *nnCountryDomain="'RU'">
                <img class="footer__logo"
                    *nnScreenMode="'DESKTOP'"
                    src="/assets/img/footer/logo-RU.png"
                    alt="logo"
                    width="209"
                    height="42"
                    loading="lazy">
            </ng-container>
            <ng-container *nnCountryDomain="'CA'">
                <img class="footer__logo"
                    *nnScreenMode="'DESKTOP'"
                    src="/assets/img/footer/logo-CA.svg"
                    alt="logo"
                    width="179"
                    height="58"
                    loading="lazy">
            </ng-container>
            <ng-container *nnCountryDomain="'UK'">
                <img class="footer__logo footer__logo_UK"
                    *nnScreenMode="'DESKTOP'"
                    src="/assets/img/footer/logo-UK.svg"
                    alt="logo"
                    width="214"
                    height="58"
                    loading="lazy">
            </ng-container>
            <ng-container *nnCountryDomain="'US'">
                <img class="footer__logo footer__logo_US"
                    *nnScreenMode="'DESKTOP'"
                    src="/assets/img/footer/logo-US.svg"
                    alt="logo"
                    width="214"
                    height="58"
                    loading="lazy">
            </ng-container>
            <nn-language-selector class="footer__lang"
                *nnScreenMode="'DESKTOP'"></nn-language-selector>
            <button class="footer__support support"
                *nnCountryDomain="'UK';negate:true"
                type="button"
                routerLink="/chat/support">
                <span class="support__media">
                    <img class="support__img"
                        src="/assets/img/common/footer_logo.svg"
                        width="48"
                        height="48"
                        alt="footer logo">
                </span>
                <span class="support__content">
                    <span class="support__title">
                        {{ 'footer|chat_support_title' | localization }}
                    </span>
                    <span class="support__badge badge badge_green"
                        *ngIf="supportAccountOnlineStatus$ | async">
                        ONLINE
                    </span>
                    <span class="support__description">
                        {{ 'footer|chat_support_description' | localization }}
                    </span>
                </span>
                <div class="support__footer">
                    <mat-icon class="support__icon mat-icon_chevron"
                        svgIcon="sprite:icon-chevron"></mat-icon>
                </div>
            </button>
        </div>
        <div class="footer__menufirst">
            <div class="footer__menu">
                <a class="footer__title"
                    *nnScreenMode="'DESKTOP'"
                    routerLink="/help">
                    {{ 'footer|need_help' | localization }}
                </a>
                <div class="footer__nav">
                    <a class="footer__nav-link"
                        routerLink="/faq">
                        {{ 'footer|faq' | localization }}
                    </a>
                    <a class="footer__nav-link"
                        routerLink="/help">
                        {{ 'footer|write_us' | localization }}
                    </a>
                    <a class="footer__nav-link"
                        href="https://blog.nannyservices.ca"
                        *nnCountryDomain="'CA'">
                        {{ 'footer|articles_and_tips' | localization }}
                    </a>
                    <a class="footer__nav-link"
                        routerLink="/blog"
                        *nnCountryDomain="'RU'">
                        {{ 'footer|articles_and_tips' | localization }}
                    </a>
                    <a class="footer__nav-link"
                        routerLink="/recurrent-payments-agreement"
                        *nnCountryDomain="'RU'">
                        {{ 'footer|recurrent_payments_agreement' | localization }}
                    </a>
                </div>
            </div>
        </div>
        <a class="footer__phone"
            *nnCountryDomain="'RU'"
            href="tel:+74996080544">
            <div class="footer__phone-icon">
                <mat-icon class="mat-icon_phone"
                    svgIcon="sprite:icon-phone"></mat-icon>
            </div>
            <div class="footer__phone-title">+7 (499) 608-0544</div>
        </a>
        <a class="footer__phone"
            *nnCountryDomain="'CA'"
            href="tel:+18883698819">
            <div class="footer__phone-icon">
                <mat-icon class="mat-icon_phone"
                    svgIcon="sprite:icon-phone"></mat-icon>
            </div>
            <div class="footer__phone-title">+1 (888) 369-8819</div>
        </a>
        <div class="footer__apps"
            *nnCountryDomain="'RU'">
            <a class="footer__app"
                [href]="'RU' | nnAppStoreLink"
                target="_blank">
                <img class="footer__app-img"
                    src="/assets/img/common/footer_appstore_ru.svg"
                    alt="Download in App Store"
                    width="168"
                    height="48">
            </a>
            <a class="footer__app"
                [href]="'RU' | nnGooglePlayLink"
                target="_blank">
                <img class="footer__app-img"
                    src="/assets/img/common/footer_play_ru.svg"
                    alt="Download in Google Play"
                    width="168"
                    height="48">
            </a>
            <a class="footer__app"
                href="https://appgallery.huawei.com/#/app/C104120013"
                target="_blank">
                <img class="footer__app-img"
                    src="/assets/img/common/footer_huawei_ru.svg"
                    alt="Download in App Gallery"
                    width="168"
                    height="48">
            </a>
        </div>
        <div class="footer__apps"
            *nnCountryDomain="'CA'">
            <a class="footer__app"
                [href]="'CA' | nnAppStoreLink"
                target="_blank">
                <img class="footer__app-img"
                    src="/assets/img/common/footer_appstore_en.svg"
                    alt="Download in App Store"
                    width="146"
                    height="49">
            </a>
            <a class="footer__app"
                [href]="'CA' | nnGooglePlayLink"
                target="_blank">
                <img class="footer__app-img"
                    src="/assets/img/common/footer_play_en.svg"
                    alt="Download in Google Play"
                    width="162"
                    height="48">
            </a>
        </div>
        <div class="footer__apps"
            *nnCountryDomain="'IL'">
            <a class="footer__app"
                [href]="'IL' | nnAppStoreLink"
                target="_blank">
                <img class="footer__app-img"
                    src="/assets/img/common/footer_appstore_en.svg"
                    alt="Download in App Store"
                    width="146"
                    height="49">
            </a>
            <a class="footer__app"
                [href]="'IL' | nnGooglePlayLink"
                target="_blank">
                <img class="footer__app-img"
                    src="/assets/img/common/footer_play_en.svg"
                    alt="Download in Google Play"
                    width="162"
                    height="48">
            </a>
        </div>
        <div class="footer__apps"
            *nnCountryDomain="'UK'">
            <a class="footer__app"
                href="https://apps.apple.com/gb/app/mumshelpers-co-uk/id1536024102"
                target="_blank">
                <img class="footer__app-img"
                    src="/assets/img/common/footer_appstore_en.svg"
                    alt="Download in App Store"
                    width="146"
                    height="49">
            </a>
        </div>
        <div class="footer__apps"
            *nnCountryDomain="'US'">
            <a class="footer__app"
                href="/src"
                target="_blank">
                <img class="footer__app-img"
                    src="/assets/img/common/footer_appstore_en.svg"
                    alt="Download in App Store"
                    width="146"
                    height="49">
            </a>
        </div>
        <div class="footer__social social"
            *nnCountryDomain="'RU'">
            <!--<a class="social__link"
                href="https://www.facebook.com/nashanyanyaru"
                target="_blank">
                <mat-icon class="mat-icon_fb"
                    svgIcon="sprite:icon-fb"></mat-icon>
            </a>-->
            <a class="social__link"
                href="https://ok.ru/nashanyanyaru">
                <mat-icon class="mat-icon_ok"
                    svgIcon="sprite:icon-ok"></mat-icon>
            </a>
            <a class="social__link"
                href="https://vk.com/nashanyanyaru">
                <mat-icon class="mat-icon_vk"
                    svgIcon="sprite:icon-vk"></mat-icon>
            </a>
            <a class="social__link"
                href="https://www.youtube.com/user/nashanyanyaru">
                <mat-icon class="mat-icon_youtube"
                    svgIcon="sprite:icon-youtube"></mat-icon>
            </a>
        </div>
        <!--<div class="footer__social social"
            *nnCountryDomain="'CA'">
            <a class="social__link"
                href="/"
                target="_blank">
                <mat-icon class="mat-icon_fb"
                    svgIcon="sprite:icon-fb"></mat-icon>
            </a>
            <a class="social__link"
                href="/">
                <mat-icon class="mat-icon_youtube"
                    svgIcon="sprite:icon-youtube"></mat-icon>
            </a>
        </div>-->
        <div class="footer__social social"
            *nnCountryDomain="'IL'">
            <a class="social__link"
                href="https://www.instagram.com/ozeretli">
                <mat-icon class="mat-icon_instagram"
                    svgIcon="sprite:icon-instagram"></mat-icon>
            </a>
        </div>
        <div class="footer__menusecond">
            <div class="footer__menu">
                <a class="footer__title"
                    *nnScreenMode="'DESKTOP'"
                    routerLink="/about">
                    {{ 'footer|about' | localization }}
                </a>
                <div class="footer__nav">
                    <a class="footer__nav-link"
                        routerLink="/terms-of-services">
                        {{ 'footer|terms_of_use' | localization }}
                    </a>
                    <a class="footer__nav-link"
                        routerLink="/privacy-policy">
                        {{ 'footer|confidentiality' | localization }}
                    </a>
                    <a class="footer__nav-link"
                       *nnCountryDomain="['RU', 'CA']"
                        routerLink="/referrals">
                        {{ 'dashboard_page|referrals' | localization }}
                    </a>
                </div>
            </div>
        </div>
        <div class="footer__copyright">{{ 'footer|copyright' | localization:currentYear }}</div>
    </div>
</footer>
